import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Snatch Grip RDL’s  6-6-6\\@75% 1RM Snatch`}</p>
    <p>{`Hang Power Snatch 6-6-6-6 to a 6 rep max`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`10-Full Snatch (135/95)`}</p>
    <p>{`20-Burpees`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Reminder: Our new Ville schedule takes effect this week!  Check it
out on the Schedule page.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`Congrats to our top 3 teams in each division from True Grit 5
yesterday.  Great turnout and lots of tough competition.  Thanks to all
that helped out to make this event happen and to our sponsors for
allowing us to give prizes to everyone on the podium.`}</em></strong></p>
    <p><a parentName="p" {...{
        "href": "https://crossfittheville.wordpress.com/2017/11/05/sunday-november-5th/true-grit-5-rx-winners/"
      }}><img parentName="a" {...{
          "src": "https://crossfittheville.files.wordpress.com/2017/11/true-grit-5-rx-winners.jpg?w=300&h=294",
          "alt": null
        }}></img>{`{.aligncenter
.size-medium .wp-image-5842 width=“300”
height=“294”}`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://crossfittheville.wordpress.com/2017/11/05/sunday-november-5th/true-grit-5-scaled-winners/"
      }}><img parentName="a" {...{
          "src": "https://crossfittheville.files.wordpress.com/2017/11/true-grit-5-scaled-winners.jpg?w=300&h=260",
          "alt": null
        }}></img>{`{.aligncenter
.size-medium .wp-image-5843 width=“300”
height=“260”}`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      